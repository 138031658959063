
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";

@Component({
  components: {
    AdminTable
  }
})
export default class AdminUserBalanceWithdraw extends Vue {
  columns = [
    {
      name: "_id",
      required: true,
      label: "ID",
      field: "_id",
      align: "left",
      type: "string",
      classes: "ellipsis",
      style: "max-width: 15px"
    },
    {
      name: "paymentId",
      required: true,
      label: "paymentId",
      field: "paymentId",
      align: "left",
      type: "string"
    },
    {
      name: "paymentSystem",
      align: "center",
      label: "paymentSystem",
      field: "paymentSystem",
      type: "string"
    },
    {
      name: "status",
      align: "center",
      label: "status",
      field: "status",
      type: "string"
    },
    {
      name: "userId",
      align: "center",
      label: "userId",
      field: "userId",
      type: "string"
    },
    {
      name: "amount",
      align: "center",
      label: "amount",
      field: "amount",
      type: "number"
    },
    {
      name: "amountWithCommission",
      align: "center",
      label: "amountWithCommission",
      field: "amountWithCommission",
      type: "number"
    },
    {
      name: "createdAt",
      align: "center",
      label: "createdAt",
      field: "createdAt",
      type: "string"
    }
  ];
}
